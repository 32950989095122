@tailwind base;
@tailwind components;
@tailwind utilities;


.fc-button {
  font-size: 14px !important;
  background-color: #319795 !important;
  font-weight: bold !important;
  border: none !important;
}

.fc-toolbar-title {
  font-size: 17px !important;
}

.fc-col-header-cell-cushion .fc-daygrid-day-number {
  font-size: 13px !important;
}

.fc-daygrid-day-number {
  font-size: 14px !important;
}

.fc-scrollgrid-section-header {
  background-color: #f9fafb !important;
}

@keyframes form-animation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.animation-form-l3 {
  animation: form-animation 0.4s ease-in-out;
}


.animation-form {
  animation: form-animation 0.3s ease-in-out;
}

.animation-item {
  animation: form-animation 0.2s ease-in-out;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-track:active {
  background-color: #999;
}

::-webkit-scrollbar-thumb:active {
  background-color: #444;
}

body {
  font-family: 'Roboto' !important;
}


.mfstcc {
    height: auto !important;
    border-radius: 5px !important;
}

.mfstccp {
    height: auto !important;
    padding-bottom: 1.5px !important;
    padding-top: 1.5px !important;
}

.css-13cymwt-control{
    height: auto !important;
    border-radius: 5px !important;
}

.mfstcc:focus {
    border: 2px solid red !important; /* Set your focus border color here */
    outline: none !important; /* Remove the default focus outline */
}